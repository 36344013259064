// Generated by Framer (f558e44)

import { addFonts, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["iDkZg8pyO"];

const serializationHash = "framer-7OHeo"

const variantClassNames = {iDkZg8pyO: "framer-v-wryyld"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "iDkZg8pyO", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-wryyld", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"iDkZg8pyO"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "stretch", positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/y8t5p43lZ8He81pMiz1QFp8iJg.png"}} className={"framer-no0xc6"} data-framer-name={"as 1"} layoutDependency={layoutDependency} layoutId={"guGpTcKHq"}/><Image background={{alt: "", fit: "fill", sizes: "57px", src: "https://framerusercontent.com/images/7Fct8Zysm1t4eR4J1IzXTgAzSY.png", srcSet: "https://framerusercontent.com/images/7Fct8Zysm1t4eR4J1IzXTgAzSY.png?scale-down-to=512 512w, https://framerusercontent.com/images/7Fct8Zysm1t4eR4J1IzXTgAzSY.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/7Fct8Zysm1t4eR4J1IzXTgAzSY.png 1255w"}} className={"framer-vmh7vv"} data-framer-name={"11 14"} layoutDependency={layoutDependency} layoutId={"Pl6LPZ2xO"}/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-7OHeo[data-border=\"true\"]::after, .framer-7OHeo [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7OHeo.framer-1brv4zk, .framer-7OHeo .framer-1brv4zk { display: block; }", ".framer-7OHeo.framer-wryyld { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 31px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 98px; }", ".framer-7OHeo .framer-no0xc6 { aspect-ratio: 0.9874999863760812 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 31px); position: relative; width: 31px; }", ".framer-7OHeo .framer-vmh7vv { aspect-ratio: 2.395348819157056 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 24px); position: relative; width: 57px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-7OHeo.framer-wryyld { gap: 0px; } .framer-7OHeo.framer-wryyld > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-7OHeo.framer-wryyld > :first-child { margin-left: 0px; } .framer-7OHeo.framer-wryyld > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 31
 * @framerIntrinsicWidth 97.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerq72q71eRI: React.ComponentType<Props> = withCSS(Component, css, "framer-7OHeo") as typeof Component;
export default Framerq72q71eRI;

Framerq72q71eRI.displayName = "scroll";

Framerq72q71eRI.defaultProps = {height: 31, width: 97.5};

addFonts(Framerq72q71eRI, [])